<template>
<div>
  <b-container fluid>
    <div class="d-flex justify-content-center" v-if="loading"><div class="spinner"></div></div>
    <div v-if='statsAvailable'>
      <div>
        <b-row>
          <b-col>
            <b-card :title='formatDate(game.startDate)'
                    :sub-title="game.location + ' vs ' + game.opponentName">
              <div v-if="isManager">
                <div class='d-none d-md-block'>
                  <b-btn class="stats" variant="primary" @click.stop="edit(game.id)">Edit Stats</b-btn>
                </div>
                <div class='d-md-none'>
                  <b-btn class="stats btn-mobile" variant="primary" @click.stop="edit(game.id)">Edit</b-btn>
                </div>
              </div>
              <b-table responsive :items='game.teamStats.t' :fields='teamStatsFields'></b-table>
            </b-card>
          </b-col>
        </b-row>
        <div class='d-none d-md-block'>
          <b-row>
            <b-col md="4" xl="3"
                  v-for='stat in game.statWidgets'
                  :key='stat.index'>
              <b-card :title="stat.name" class="stat-widget">
                <span class='stat-breakdown-title'>Total</span>
                <b-progress class="mt-1" :max="max">
                    <b-progress-bar v-if="stat.total.us.perc > 0 || stat.total.them.perc > 0" :value="stat.total.us.perc" :label="stat.total.us.count.toString()" variant="primary"></b-progress-bar>
                    <b-progress-bar v-if="stat.total.us.perc > 0 || stat.total.them.perc > 0" :value="stat.total.them.perc" :label="stat.total.them.count.toString()" variant="danger"></b-progress-bar>
                </b-progress>
                <span class='stat-breakdown-title'>Period 1</span>
                <b-progress class="mt-1" :max="max">
                  <b-progress-bar v-if="stat.p1.us.perc > 0 || stat.p1.them.perc > 0" :value="stat.p1.us.perc" :label="stat.p1.us.count.toString()" variant="primary"></b-progress-bar>
                  <b-progress-bar v-if="stat.p1.us.perc > 0 || stat.p1.them.perc > 0" :value="stat.p1.them.perc" :label="stat.p1.them.count.toString()" variant="danger"></b-progress-bar>
                </b-progress>
                <span class='stat-breakdown-title'>Period 2</span>
                <b-progress class="mt-1" :max="max">
                  <b-progress-bar v-if="stat.p2.us.perc > 0 || stat.p2.them.perc > 0" :value="stat.p2.us.perc" :label="stat.p2.us.count.toString()" variant="primary"></b-progress-bar>
                  <b-progress-bar v-if="stat.p2.us.perc > 0 || stat.p2.them.perc > 0" :value="stat.p2.them.perc" :label="stat.p2.them.count.toString()" variant="danger"></b-progress-bar>
                </b-progress>
                <span class='stat-breakdown-title'>Period 3</span>
                <b-progress class="mt-1" :max="max">
                  <b-progress-bar v-if="stat.p3.us.perc > 0 || stat.p3.them.perc > 0" :value="stat.p3.us.perc" :label="stat.p3.us.count.toString()" variant="primary"></b-progress-bar>
                  <b-progress-bar v-if="stat.p3.us.perc > 0 || stat.p3.them.perc > 0" :value="stat.p3.them.perc" :label="stat.p3.them.count.toString()" variant="danger"></b-progress-bar>
                </b-progress>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row class='d-md-none'>
        <b-col>
          <b-card>
            <table class='small-stats-table'>
              <tr>
                <td class='stats-name text-left bold'>{{ team.name }}</td>
                <td class='stats-name text-right bold'>{{ game.opponentName }}</td>
              </tr>
              </table>
              <b-table :items='game.statLines' :fields='statFields' :fixed='fixed' :filter='total'
                        thead-class='hidden_header'
                        @row-clicked='onRowClicked'
                        :class="tableClass">
                <template slot="row-details">
                  <table class='table b-table b-table-fixed breakdown subStats'>
                    <tr v-for='stat in validStatLines'
                        :key='stat.index'>
                      <td class='text-left statsUs'>
                        {{ stat.us }}
                      </td>
                      <td class='text-center statsName'>
                        {{ stat.period }}
                      </td>
                      <td class='text-right statsThem'>
                        {{ stat.them }}
                      </td>
                    </tr>
                  </table>
                </template>
              </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card :title='team.name' class='card-mobile card-mobile-condensed'>
            <b-table responsive striped :items='game.playerStats'
                    :fields='playerFields'
                    :sort-by.sync='sorting.players.sortBy'
                    :sort-desc.sync='sorting.players.desc'
                    :sort-direction='sorting.players.direction'>
            </b-table>
            <b-table responsive striped :items='goalieStatsArr'
                    :fields='goalieStatsFields'>
            </b-table>
          </b-card>
          <!-- <b-card :title='game.opponentName'>
              <b-table striped hover :items='playerStats' :fields='playerFields'></b-table>
            </b-card> -->
        </b-col>
      </b-row>
    </div>
    <b-row v-if='!statsAvailable && !loading' >
      <b-col>
        <b-card :title='formatDate(game.startDate)'
                :sub-title="game.location + ' vs ' + game.opponentName">
          <h6 class="text-center">No stats have been logged yet!<br/><br/>
          <b-button variant="info" v-if='!isManager' @click.stop="reload()" href="">Check Again!</b-button>
          <!-- Do a better job of figuring out this context -->
          <b-button variant="primary" v-if='isManager' @click.stop="edit(game.id)">Add Stats</b-button>&nbsp;&nbsp;&nbsp;
          <b-button variant="primary" v-if='isManager' @click.stop="live()">Log Live!</b-button>
          <!-- <b-button variant="primary" v-if='isManager && !isFuture' @click.stop="edit(game.tsGameId)">Add Stats!</b-button>
          <b-button variant="primary" v-if='isManager && isFuture' @click.stop="live()">Log Live!</b-button></h6> -->
          </h6>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>

import moment from 'moment'
import ProcessGameStats from '@/mixins/ProcessGameStats'
import { store } from '../store'

export default {
  name: 'GameDetail',
  mixins: [ProcessGameStats],
  data () {
    return {
      loading: true,
      statsAvailable: false,
      sorting: {},
      max: 1,
      gameScoreFields: [
        { key: 'team', label: 'Final' },
        { key: 'p1', label: '1st', 'class': 'text-center' },
        { key: 'p2', label: '2nd', 'class': 'text-center' },
        { key: 'p3', label: '3rd', 'class': 'text-center' },
        { key: 't', label: 'T', 'class': 'text-center' }
      ],
      teamStatsFields: {},
      goalieStatsFields: [
        { key: 'jerseyNumber', label: '#', 'class': 'text-left' },
        { key: 'name', label: 'Goalies', 'class': 'text-left text-nowrap' },
        { key: 'shots', label: 'Shots', 'class': 'text-center' },
        { key: 'ga', label: 'GA', 'class': 'text-center' },
        { key: 'svperc', label: 'SV%','class': 'text-center',
          formatter: (value) => {
            return value.toFixed(3)
          }
        }
      ],
      playerStatsSortBy: 'jerseyNumber',
      playerStatsDesc: false,
      playerFields: [
        { key: 'jerseyNumber', label: '#', 'class': 'text-left' },
        { key: 'name', label: 'Name', 'class': 'text-left text-nowrap' },
        { key: 'goals', label: 'G', sortable: true, 'class': 'text-center' },
        { key: 'assists', label: 'A', sortable: true, 'class': 'text-center' },
        { key: 'points', label: 'P', sortable: true, 'class': 'text-center' },
        { key: 'plusminus', label: '+/-', sortable: true, 'class': 'text-center' },
        { key: 'ppg', label: 'PPG', sortable: true, 'class': 'text-center' },
        { key: 'shg', label: 'SHG', sortable: true, 'class': 'text-center' },
        { key: 'pim', label: 'PIM', sortable: true, 'class': 'text-center' }
      ],
      team: {},
      games: [],
      errors: [],
      statFields: [],
      total: 'total',
      fixed: true,
      tableClass: 'breakdown',
      isMember: false,
      isManager: false,
      backLink: '',
      teamLink: ''
    }
  },
  head: {
    title () {
      return {
        inner: this.team.name
      }
    },
    meta () {
      return [
        { name: 'description', content: this.game.opponentName + ' v. ' + this.team.name, id: 'desc' }
      ]
    }
  },
  computed: {
    game () {
      return store.state.game
    },
    goalieStatsArr: function () {
      let arr = []
      for (const stats of Object.values(this.game.goalieStats)) {
        arr.push(stats)
      }
      return arr
    },
    validStatLines: function () {
      return this.game.statLines.filter(stat => (stat.period !== "total" && stat.us > -1))
      // v-if='stat.name === row.item.name && stat.period !== "total" && stat.us > -1'
    }
  },
  async created () {
    window.scrollTo(0, 0)
    this.loading = true
    this.showTeam = false
    this.statsAvailable = false
    let gameId = this.$route.params.gameId
    let teamId = this.$route.params.teamId
    this.userProfile = store.state.userProfile
    if (this.userProfile.teams.includes(teamId)) {
      this.isMember = true
      if (this.userProfile.manages.includes(teamId)) {
        this.isManager = true
      }
      await store.commit('setCurrentTeamId', teamId)
      await store.dispatch('bindTeamData')
      this.team = store.state.team
      if (this.team.name) {
        this.setNav()
        await store.commit('setCurrentGameId', gameId)
        await store.dispatch('bindGameData')
        if ('stats' in this.game) {
          this.processGameStats(this.team, this.game).then(result => {
            console.log('Process complete: ', result)
            this.buildSummaryFields()
            this.statsAvailable = true
            this.loading = false
            this.showTeam = true
            this.$emit('updateHead')
          })
        } else {
          this.statsAvailable = false
          this.loading = false
          this.showTeam = true
          this.$emit('updateHead')
        }
      }
    } else {
      this.isMember = false
      this.loading = false
      console.log('Not a member')
    }
    this.sorting = {
      players: {
        sortBy: 'jerseyNumber',
        desc: false,
        direction: 'desc'
      },
      goalies: {
        sortBy: 'shots',
        desc: true,
        direction: 'desc'
      },
      futureGames: {
        sortBy: 'startDate',
        desc: false,
        direction: 'asc'
      },
      pastGames: {
        sortBy: 'startDate',
        desc: false,
        direction: 'asc'
      }
    }
    this.statFields = [
      // filter: { label: '', 'class': 'text-left filter' },
      { key: 'us', label: 'US', 'class': 'text-left statsUs' },
      { key: 'name', label: '', 'class': 'text-center statsName' },
      { key: 'them', label: 'THEM', 'class': 'text-right statsThem' }
    ]
  },
  methods: {
    setNav () {
      store.commit('updateNav', {
        backLink: {
          name: 'TeamDetail',
          params: { teamId: this.team.id }
        },
        teamLink: {
          name: 'TeamDetail',
          params: { teamId: this.team.id }
        },
        teamName: this.team.name
      })
    },
    buildSummaryFields () {

      let teamStatsFields = [
        { key: 'team', label: '',
          'class': 'text-left text-nowrap',
          formatter: (value, key, item) => {
            return item.team.split(" ").shift();
          }
        },
        { key: 'goals', label: 'GF',
          'class': 'text-center text-nowrap'
        },
        { key: 'shots', label: 'SOG',
          'class': 'text-center text-nowrap'
        },
        { key: 'shotPerc', label: 'SHOT%',
          'class': 'text-center',
          formatter: (value, key, item) => {
            return Math.round(item.goals / item.shots * 100) + '%'
          }
        },
        { key: 'powerplayPerf', label: 'PP',
          'class': 'text-center'
        },
        { key: 'shorthandedgoals', label: 'SHG',
          'class': 'text-center'
        },
        { key: 'penalties', label: 'PEN',
          'class': 'text-center text-nowrap'
        }
      ]

      this.team.statCategories.forEach(stat => {
        if (!stat.default) {
          if (stat.type !== '%') {
            let newStat = {
              key: stat.name,
              label: stat.shortName,
              'class': 'text-center text-nowrap'
            }
            teamStatsFields.push(newStat)
          } else {
            let newStat = {
              key: stat.name + 'Perc',
              label: stat.shortName + '%',
              'class': 'text-center text-nowrap',
              formatter: (value) => {
                return Math.round(value * 100) + '%'
              }
            }
            teamStatsFields.push(newStat)
          }
        }
      })
      this.teamStatsFields = teamStatsFields
    },
    isFuture () {
      if (moment(this.game.startDate).isAfter()) {
        return true
      } else {
        return false
      }
    },
    formatDate (value) {
      if (!value) return ''
      return moment(value.toDate()).format('MMMM D, YYYY')
    },
    reload () {
      this.$router.go()
    },
    onRowClicked (item) {
      item._showDetails = !item._showDetails
    },
    edit (gameId) {
      this.$router.push({
        name: 'GameStatsInput',
        params: { gameId: gameId, teamId: this.team.id }
      })
    },
    live () {
      this.$router.push({
        name: 'LiveStatsInput',
        params: { gameId: this.game.id, teamId: this.team.id }
      })
    }
  }
}
</script>
